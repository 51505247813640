.custom-marker-icon {
    background: transparent !important;
}

.icon-large {
    font-size: 30px;
    color: #002b5f;
}

.redes-icon{
    font-size: 30px;
}