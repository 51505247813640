/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

.images-details-properties {
    width: 10px;
    height: 10px;
    margin-right: 5px;
}

.icons-details {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.buttons-details-properties {
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-last-add-properties {
    margin-bottom: 20px !important;
}

.properties-form-select-province {
    z-index: 1002;
}

.properties-form-select {
    z-index: 1001;
}

.filter-right{
    display: flex;
    justify-content: end;
}

@media (max-width: 767px){
    .filter-right{
        display: flex;
        justify-content: normal;
        margin-top: 10px;
    }
}

p{
    font-size: 15px;
}

.page-title-box h4{
    font-weight: 600 !important;
}

.total-a-pagar{
    margin: 0px;
    text-align: center;
}

.slider-image {
    width: 100%; /* Ajusta el ancho al contenedor */
    height: 500px; /* Altura fija para todas las imágenes */
    object-fit: cover; /* Recorta las imágenes manteniendo su proporción */
    border-radius: 0px; /* Añade bordes redondeados si es necesario */
}

.no-information{
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
}

.parrafo-cuentas{
    font-size: 14px;
}

@media (max-width: 767px){
    .w-100-mobile{
        width: 100%;
    }

    .mb-2-mobile{
        margin-bottom: 20px;
    }

    .mt-2-mobile{
        margin-top: 20px;
    }

    .total-a-pagar{
        margin: 20px 0;
        text-align: left;
    }
}

.price-number{
    font-size: 20px !important; 
    font-weight: 500;
    margin-bottom: 10px;
}

.normal-month{
    font-size: 16px;
    font-weight: 400;
    color: #4D4D4D;
}

.title-property{
    font-size: 20px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 3em; /* Ajusta según la altura de la línea */
    line-height: 1.5em; /* Ajusta según la altura de la línea */
}

.address-property{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.5em; /* Ajusta según la altura de la línea */
    line-height: 1.5em; /* Ajusta según la altura de la línea */
    margin-bottom: 10px;
}

.mr-2{
    margin-right: 20px;
}

.caracteristicas-propiedades{
    font-size: 14px;
    font-weight: 500;
}

.parrafo-detalles{
    font-size: 14px;
    margin-bottom: 0;
}

.text-bold{
    font-weight: 500;
}

.cover-property{
    width: 100%;
    height: 200px,;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.imagen-agente{
    width: 50px;
    margin-right: 10px;
}

@media (min-width: 768px){
    .betica-derecha{
        justify-content: end;
    }
}

.estrella{
    background: white;
    width: 30px;
    height: 30px;
    padding: 10px;
    position: absolute;
    top: 6%;
    left: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    z-index: 1;
    box-shadow: 2px 5px 10px #00000030;
}

.estrella.featured{
    background: #1dcea3;
}

.iconAction{
    font-size: 20px;
}

.enlace-tabla{
    color: #1dcea3;
    text-decoration: underline;
    font-weight: 600;
}

.contrato-activo-buttons{
    display: flex;
    justify-content: end;
    gap: 1.5rem;
}

@media (max-width: 480px){

    .contrato-activo-buttons{
        display: flex;
        justify-content: space-between;
        gap: 1.5rem;
        margin-top: 20px;
    }
}