.LoaderContainer {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    position: fixed;
    z-index: 9999;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.LoaderComponent {
    display: block;
    margin: 0 auto;
    border-color: red;
}